import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=13ef6cf8&scoped=true"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"
import style0 from "@/assets/sass/dashboard.scss?vue&type=style&index=0&id=13ef6cf8&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ef6cf8",
  null
  
)

export default component.exports